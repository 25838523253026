import request from './request.js';

export default {

  authPasswordLogin(_data) {
    //管理员登录接口
    return request({
      url: '/system/login/authPasswordLogin?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  uploadList(_data) {
    //文件上传
    return request({
      url: '/system/upload/uploadList',
      method: 'post',
      data: _data,
    });
  },
  evaluatioTemplateInitEvaluatioIndexList(_data) {
    //评价参数库列表
    return request({
      url: '/system/evaluatioTemplate/initEvaluatioIndexList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  evaluatioTemplateSaveEvaluatioIndex(_data) {
    //添加编辑评价参数库
    return request({
      url: '/system/evaluatioTemplate/saveEvaluatioIndex?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  evaluatioTemplateRemoveEvaluatioIndex(_data) {
    //删除评价参数库
    return request({
      url: '/system/evaluatioTemplate/removeEvaluatioIndex?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  evaluatioTemplateInitEvaluatioTemplateList(_data) {
    //评价模版列表
    return request({
      url: '/system/evaluatioTemplate/initEvaluatioTemplateList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  evaluatioTemplateRemoveEvaluatioTemplate(_data) {
    //删除评价模版
    return request({
      url: '/system/evaluatioTemplate/removeEvaluatioTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  evaluatioTemplateSaveEvaluatioTemplate(_data) {
    //添加评价模版
    return request({
      url: '/system/evaluatioTemplate/saveEvaluatioTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  evaluatioTemplateGetEvaluatioTemplateDetail(_data) {
    //评价模版详情
    return request({
      url: '/system/evaluatioTemplate/getEvaluatioTemplateDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoActivityInitCategoryList(_data) {
    //分类列表
    return request({
      url: '/system/autoActivity/initCategoryList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoActivityInOrUpCategory(_data) {
    //添加-编辑分类
    return request({
      url: '/system/autoActivity/inOrUpCategory?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  autoActivityRemoveCategory(_data) {
    //删除分类
    return request({
      url: '/system/autoActivity/removeCategory?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  autoActivityCategoryManage(_data) {
    //设置活动模版信息
    return request({
      url: '/system/autoActivity/categoryManage?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  autoActivityCategoryDetail(_data) {
    //活动模版详情
    return request({
      url: '/system/autoActivity/categoryDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  departmentDepartmentList(_data) {
    //科室列表
    return request({
      url: '/system/department/departmentList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  departmentRemoveDepartment(_data) {
    //删除科室
    return request({
      url: '/system/department/removeDepartment?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  accountInitAccountPage(_data) {
    //用户列表
    return request({
      url: '/system/account/initAccountPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  accountFreezeAndRecovery(_data) {
    //冻结|恢复
    return request({
      url: '/system/account/freezeAndRecovery?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  accountEditUserMobile(_data) {
    //修改用户手机号
    return request({
      url: '/system/account/editUserMobile?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  accountEditUserInfo(_data) {
    //修改资料
    return request({
      url: '/system/account/editUserInfo?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  accountEditUserPassWord(_data) {
    //修改密码
    return request({
      url: '/system/account/editUserPassWord?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  gradeList(_data) {
    //年级列表
    return request({
      url: '/system/grade/list?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  activityList(_data) {
    //活动列表
    return request({
      url: '/system/autoActivity/initActivityList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoActivityInsertActivity(_data) {
    //添加活动
    return request({
      url: '/system/autoActivity/insertActivity?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  autoActivityGetActivityDetail(_data) {
    //活动详情
    return request({
      url: '/system/autoActivity/getActivityDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoActivityGetActivityUserList(_data) {
    //活动学员-督导-教师列表
    return request({
      url: '/system/autoActivity/getActivityUserList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoActivitySignIn(_data) {
    //活动学员-补签
    return request({
      url: '/system/autoActivity/signIn?data=' + JSON.stringify(_data),
      method: 'POST',
    });
  },
  autoActivityGetActivityFile(_data) {
    //活动-文件图片列表
    return request({
      url: '/system/autoActivity/getActivityFile?data=' + JSON.stringify(_data),
      method: 'POST',
    });
  },
  autoActivityInsertActivityFileImg(_data) {
    //活动-添加修改图片
    return request({
      url: '/system/autoActivity/insertActivityFileImg?data=' + JSON.stringify(_data),
      method: 'POST',
    });
  },
  autoActivityGetCommentSummaryDto(_data) {
    //活动-评教汇总基础数据
    return request({
      url: '/system/autoActivity/getCommentSummaryDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoActivityGetActivityUserEvaluatioList(_data) {
    //活动-评价内容
    return request({
      url: '/system/autoActivity/getActivityUserEvaluatioList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initOperationLogPage(_data) {
    //操作日志列表
    return request({
      url:
        '/system/logger/initOperationLoggerPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  bannerDelete(_data) {
    //轮播图删除接口
    return request({
      url: '/system/banner/bannerDelete?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  bannerUpdate(_data) {
    //轮播图修改接口
    return request({
      url: '/system/banner/bannerUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  bannerList(_data) {
    //轮播图列表接口
    return request({
      url: '/system/banner/bannerList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  newsList(_data) {
    //轮播图列表接口
    return request({
      url: '/system/article/articleList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  rotationPlanEditRotationPlan(_data) {
    //修改轮转计划
    return request({
      url: '/system/rotationPlan/editRotationPlan?data=' + JSON.stringify(_data),
      method: 'POST',
    });
  },
  editRole(_data) {
    //编辑角色
    return request({
      url: '/api/gkAdminRole/editRole?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  createRole(_data) {
    //添加角色
    return request({
      url: '/api/gkAdminRole/createRole?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  distributionPermissionForRole(_data) {
    //分配角色权限
    return request({
      url:
        '/api/gkAdminRole/distributionPermissionForRole?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },
  getBindingPermission(_data) {
    //获取角色已绑定权限
    return request({
      url:
        '/api/gkAdminRole/getBindingPermission?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  removeRole(_data) {
    //删除角色
    return request({
      url: '/api/gkAdminRole/removeRole?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  roleList(_data) {
    //身份列表初始化
    return request({
      url: '/api/gkAdminRole/roleList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  getMenuTree() {
    //获取所有菜单树
    return request({
      url: '/api/permission/getMenuTree',
      method: 'get',
    });
  },
  initAdminPage(_data) {
    //管理员列表初始化
    return request({
      url: '/api/gkAdminCenter/adminList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  removeAdmin(_data) {
    //删除管理员
    return request({
      url: '/api/gkAdminCenter/delete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  editAdmin(_data) {
    //编辑管理员
    return request({
      url: '/api/gkAdminCenter/update?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  createAdmin(_data) {
    //添加管理员
    return request({
      url: '/api/gkAdminCenter/insert?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  // 系统消息 start-----------------------------------------------------------------

  addMessage(_data, html) {
    // 发布消息
    return request({
      url: '/api/gkMssage/insert?data=' + JSON.stringify(_data),
      method: 'post',
      data: {
        content: html,
      },
    });
  },

  deleteMessage(_data) {
    // 删除系统消息
    return request({
      url: '/api/gkMssage/delete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  findMessageList(_data) {
    // 系统消息列表
    return request({
      url: '/api/gkMssage/messageList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  choiceUserList(_data) {
    // 选择用户列表
    return request({
      url: '/system/account/initAccountPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  // 系统消息 end-----------------------------------------------------------------
  gkMssageMessageTemplateList(_data) {
    // 消息模版列表
    return request({
      url: '/api/gkMssage/messageTemplateList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  gkMssageEditMessageTemplate(_data) {
    // 修改消息模版
    return request({
      url: '/api/gkMssage/editMessageTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  articleList(_data) {
    //文章列表
    return request({
      url: '/system/article/articleList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  departmentInsertDepartment(_data) {
    //添加修改列表
    return request({
      url: '/system/department/insertDepartment?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  departmentUserList(_data) {
    //负责人列表
    return request({
      url: '/system/departmentUser/list?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  departmentUserEditAdd(_data) {
    //添加修改负责人
    return request({
      url: '/system/departmentUser/editAdd?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  departmentUserRemove(_data) {
    //删除负责人
    return request({
      url: '/system/departmentUser/remove?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },











































  autoCategoryInitCategoryList(_data) {
    //帖子类型列表
    return request({
      url: '/system/autoCategory/initCategoryList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoCategoryInOrUpCategory(_data) {
    //帖子类型添加/编辑
    return request({
      url: '/system/autoCategory/inOrUpCategory?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoCategoryCategoryManage(_data) {
    //帖子管理(添加字段)
    return request({
      url: '/system/autoCategory/categoryManage?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  autoCategoryCategoryDetails(_data) {
    //帖子类型管理-回显
    return request({
      url: '/system/autoCategory/categoryDetails?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoCategoryInitLabelList(_data) {
    //标签列表
    return request({
      url: '/system/autoCategory/initLabelList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  autoCategoryInsertLabelReq(_data) {
    //标签管理-添加标签
    return request({
      url: '/system/autoCategory/insertLabelReq?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  autoCategoryRemoveLabel(_data) {
    //标签管理-删除标签
    return request({
      url: '/system/autoCategory/removeLabel?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  checkNoteGetCheckNoteList(_data) {
    //帖子列表
    return request({
      url: '/system/checkNote/getCheckNoteList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  checkNoteCheckNote(_data) {
    //帖子管理-审核
    return request({
      url: '/system/checkNote/checkNote?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  checkIsTuiJian(_data) {
    //帖子管理-推荐
    return request({
      url: '/system/checkNote/isTuiJian?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  checkNoteGetNoteDetail(_data) {
    //帖子详情
    return request({
      url: '/system/checkNote/getNoteDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  financeList(_data) {
    //财务列表
    return request({
      url: '/api/finance/financeList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },


  exportFinanceListTemplate(_data) {
    //导出财务列表
    return request({
      url:
        '/api/finance/exportFinanceListTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  exportUserWithdrawListTemplate(_data) {
    //导出用户提现列表
    return request({
      url:
        '/api/finance/exportUserWithdrawListTemplate?data=' +
        JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  userWithdrawUpdate(_data) {
    //用户提现操作
    return request({
      url: '/api/finance/userWithdrawUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  userWithdrawList(_data) {
    //用户提现列表
    return request({
      url: '/api/finance/userWithdrawList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },


  

  adminDetail(_data) {
    //管理员详情
    return request({
      url: '/api/gkAdminCenter/adminInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  

  

 




  

  

  

  

  aboutUs() {
    //关于我们
    return request({
      url: '/system/aboutUs/aboutUs',
      method: 'get',
    });
  },

  updateAboutUs(_data) {
    //修改关于我们
    return request({
      url: '/system/aboutUs/updateAboutUs?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  articleDelete(_data) {
    //文章删除
    return request({
      url: '/system/article/articleDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },


  



  shopWithdrawListDto(_data) {
    //零售商提现列表
    return request({
      url: '/api/finance/shopWithdrawListDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  
  exportUserWithdrawListShopTemplate(_data) {
    //导出用户提现列表
    return request({
      url:
        '/api/finance/exportUserWithdrawListShopTemplate?data=' +
        JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  shopWithdrawUpdate(_data) {
    //店铺提现操作
    return request({
      url: '/api/finance/shopWithdrawUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },


  gkAdminCenterAdminList(_data) {
    //用户列表初始化
    return request({
      url: '/api/gkAdminCenter/adminList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },


  departmentDepartmentList(_data) {
    //科室列表
    return request({
      url: '/system/department/departmentList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  rotationPlanGetRotationPlanList(_data) {
    //学生轮转列表
    return request({
      url: '/system/rotationPlan/getRotationPlanList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  rotationPlanGetRotationPlanDetail(_data) {
    //查看轮转计划表
    return request({
      url: '/system/rotationPlan/getRotationPlanDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  rotationPlanExportTemplate(_data) {
    //下载轮转模版
    return request({
      url:
        '/system/rotationPlan/exportTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },







































  advertiseUpdate(_data) {
    //广告修改接口
    return request({
      url: '/system/banner/advertiseUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  deleteLocalLifeBanner(_data) {
    //删除线下banner
    return request({
      url: '/system/banner/deleteLocalLifeBanner?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  updateLocalLifeBanner(_data) {
    //修改线下banner
    return request({
      url: '/system/banner/updateLocalLifeBanner?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  addLocalLifeBanner(_data) {
    //添加线下banner
    return request({
      url: '/system/banner/addLocalLifeBanner?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getLocalLifeBannerList(_data) {
    //获取线下banner列表
    return request({
      url:
        '/system/banner/getLocalLifeBannerList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },



  getToken() {
    //获取上传token
    return request({
      url: '/system/upload/getToken',
      method: 'get',
    });
  },

  articleInsertOrUpdate(_data, html) {
    //文章添加或更改
    return request({
      url:
        '/system/article/articleInsertOrUpdate?data=' + JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },

  auditDetail(_data) {
    //商户入驻审核详情
    return request({
      url: '/system/shopAudit/auditDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initFreightTemplates(_data) {
    //初始化运费模板
    return request({
      url: '/system/freight/initFreightTemplates?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  createFreightTemplate(_data) {
    //添加运费模板
    return request({
      url:
        '/system/freight/createFreightTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  editTemplateInfo(_data) {
    //（添加-删除-编辑）模板信息
    return request({
      url: '/system/freight/editTemplateInfo?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  freightTemplateDetail(_data) {
    //运费模板详情
    return request({
      url:
        '/system/freight/freightTemplateDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  removeFreightTemplate(_data) {
    //删除运费模板
    return request({
      url:
        '/system/freight/removeFreightTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  setDefaultTemplate(_data) {
    //设置默认模板
    return request({
      url: '/system/freight/setDefaultTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  auditRemove(_data) {
    //审核删除
    return request({
      url: '/system/shopAudit/delete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  auditRefuse(_data) {
    //审核拒绝
    return request({
      url: '/system/shopAudit/auditRefuse?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  shopAuditList(_data) {
    //初始化入驻审核页
    return request({
      url: '/system/shopAudit/shopAuditList?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  submitSetShareBenefit(_data) {
    //提交设置分润比例
    return request({
      url:
        '/system/shopAudit/submitSetShareBenefit?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getShopBaseInfo(_data) {
    //商户详情
    return request({
      url: '/system/shop/getShopBaseInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  freezeShopAccount(_data) {
    //冻结商户账号
    return request({
      url: '/system/shop/freezeShopAccount?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  initShopListPage(_data) {
    //商户管理列表
    return request({
      url: '/system/shop/initShopListPage?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  resetPasswordForShop(_data) {
    //重置密码
    return request({
      url: '/system/shop/resetPasswordForShop?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  consumerList(_data) {
    //消费账单
    return request({
      url: '/system/account/consumerList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  recommendList(_data) {
    //推荐人数
    return request({
      url: '/system/account/recommendList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  followShop(_data) {
    //关注店铺列表
    return request({
      url: '/system/account/followShop?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  freezeAndRecovery(_data) {
    //冻结|恢复
    return request({
      url: '/system/account/freezeAndRecovery?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getUserBaseInfoForShop(_data) {
    //用户详情
    return request({
      url:
        '/system/account/getUserBaseInfoForShop?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  applyList(_data) {
    //合伙人申请列表
    return request({
      url: '/system/partner/applyList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  partnerUserList(_data) {
    //区域下的合伙人列表
    return request({
      url: '/system/partner/partnerUserList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  insertPartner(_data) {
    //添加区域
    return request({
      url: '/system/partner/insertPartner?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  insertUser(_data) {
    //添加代理人
    return request({
      url: '/system/partner/insertUser?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  partnerDetail(_data) {
    //代理商明细
    return request({
      url: '/system/partner/partnerDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateSystemSetup(_data) {
    //修改分佣比例
    return request({
      url: '/system/partner/updateSystemSetup?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  forgetPasswordAuthIdentity(_data) {
    //忘记密码-身份验证
    return request({
      url:
        '/system/login/forgetPasswordAuthIdentity?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  forgetPasswordSubmitChange(_data) {
    //忘记密码-修改密码
    return request({
      url:
        '/system/login/forgetPasswordSubmitChange?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  getAllRoles() {
    //所有身份列表
    return request({
      url: '/api/gkAdminRole/getAllRoles',
      method: 'get',
    });
  },

  getAdminWithTheMenuTree() {
    //获取用户拥有的菜单树
    return request({
      url: '/api/permission/getAdminWithTheMenuTree',
      method: 'get',
    });
  },

  initShopShareBenefit(_data) {
    //商户分润比例设置初始化
    return request({
      url: '/system/audit/initShopShareBenefit?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  recoveryShopAccount(_data) {
    //恢复商户账号
    return request({
      url: '/system/shop/recoveryShopAccount?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopGoodsPage(_data) {
    //商品列表
    return request({
      url: '/system/shop/getShopGoodsPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initShopLocalLifeOrderPage(_data) {
    //店铺线下订单列表
    return request({
      url:
        '/system/shop/initShopLocalLifeOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  localLifeShopOrderDetail(_data) {
    //店铺线下订单详情
    return request({
      url:
        '/system/shop/localLifeShopOrderDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopWithdrawalAuditPage(_data) {
    //店铺提现审核列表初始化
    return request({
      url:
        '/system/shop/getShopWithdrawalAuditPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopGoodsOrderPage(_data) {
    //商品订单
    return request({
      url: '/system/shop/getShopGoodsOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopWithdrawalLPage(_data) {
    //店铺提现列表初始化
    return request({
      url: '/system/shop/getShopWithdrawalLPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopBalanceLogPage(_data) {
    //店铺余额列表初始化
    return request({
      url: '/system/shop/getShopBalanceLogPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getUserGoodsOrderPage(_data) {
    //商品订单
    return request({
      url:
        '/system/account/getUserGoodsOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getPlatformCategories() {
    //平台商品分类列表
    return request({
      url: '/system/goodsCategory/getPlatformCategories',
      method: 'get',
    });
  },

  addeditlocalLifeCategory(_data) {
    //添加编辑分类
    return request({
      url:
        '/system/localLifeCategory/addeditlocalLifeCategory?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  deletelocalLifeCategory(_data) {
    //删除线下店铺分类
    return request({
      url:
        '/system/localLifeCategory/deletelocalLifeCategory?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  localLifeCategoryList() {
    //线下店铺分类列表
    return request({
      url: '/system/localLifeCategory/localLifeCategoryList',
      method: 'get',
    });
  },


  initProvinceList(_data) {
    // 省列表初始化
    return request({
      url: '/system/region/initProvinceList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  addeditRegion(_data) {
    // 添加编辑省市区商圈
    return request({
      url: '/system/region/addeditRegion?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  removeRegion(_data) {
    // 删除省市区商圈
    return request({
      url: '/system/region/removeRegion?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  // app版本管理 start-------------------------------------------------------------------------------

  userCreate(_data) {
    // 新增用户端版本
    return request({
      url: '/system/app-version/create?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  userGetNow() {
    // 获取用户端当前最新版本
    return request({
      url: '/system/app-version/getNow',
      method: 'get',
    });
  },

  userPage(_data) {
    // 获取用户端版本列表
    return request({
      url: '/system/app-version/page?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  shopCreate(_data) {
    // 新增商铺端版本
    return request({
      url: '/system/app-version/shop-create?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  shopGetNow() {
    // 获取商铺端当前最新版本
    return request({
      url: '/system/app-version/shop-getNow',
      method: 'get',
    });
  },

  shopPage(_data) {
    // 获取商铺端版本列表
    return request({
      url: '/system/app-version/shop-page?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  shopSync(_data) {
    // 编辑商铺端版本
    return request({
      url: '/system/app-version/shop-sync?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  userSync(_data) {
    // 编辑用户端版本
    return request({
      url: '/system/app-version/sync?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  // app版本管理 end---------------------------------------------------------------------------------

  createKnowXiangMai(_data) {
    // 新增知道响买
    return request({
      url: '/system/the-text/createKnowXiangMai?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  knowXiangMaipage(_data) {
    // 知道响买分页列表
    return request({
      url: '/system/the-text/knowXiangMaipage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateKnowXiangMai(_data) {
    // 编辑知道响买
    return request({
      url: '/system/the-text/updateKnowXiangMai?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteKnowXiangMai(_data) {
    // 删除知道响买{id}
    return request({
      url: '/system/the-text/deleteKnowXiangMai/' + _data,
      method: 'get',
    });
  },
};
